export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const MY_ALL_ORDER_REQUEST = "MY_ALL_ORDER_REQUEST";
export const MY_ALL_ORDER_SUCCESS = "MY_ALL_ORDER_SUCCESS";
export const MY_ALL_ORDER_FAIL = "MY_ALL_ORDER_FAIL";

export const ORDERS_LIST_ADMIN_REQUEST = "ORDERS_LIST_ADMIN_REQUEST";
export const ORDERS_LIST_ADMIN_SUCCESS = "ORDERS_LIST_ADMIN_SUCCESS";
export const ORDERS_LIST_ADMIN_FAIL    = "ORDERS_LIST_ADMIN_FAIL   ";

export const REMOVE_ORDER_ADMIN_REQUEST = "REMOVE_ORDER_ADMIN_REQUEST";
export const REMOVE_ORDER_ADMIN_SUCCESS = "REMOVE_ORDER_ADMIN_SUCCESS";
export const REMOVE_ORDER_ADMIN_FAIL    = "REMOVE_ORDER_ADMIN_FAIL   ";

export const UPDATE_ORDERSTATUS_ROLE_ADMIN_REQUEST = "UPDATE_ORDERSTATUS_ROLE_ADMIN_REQUEST";
export const UPDATE_ORDERSTATUS_ROLE_ADMIN_SUCCESS = "UPDATE_ORDERSTATUS_ROLE_ADMIN_SUCCESS";
export const UPDATE_ORDERSTATUS_ROLE_ADMIN_FAIL    = "UPDATE_ORDERSTATUS_ROLE_ADMIN_FAIL   ";

export const CLEAR_ERROR = "CLEAR_ERROR";
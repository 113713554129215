export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const USER_UPDATE_pass_REQUEST = "USER_UPDATE_pass_REQUEST";
export const USER_UPDATE_pass_SUCCESS = "USER_UPDATE_pass_SUCCESS";
export const USER_UPDATE_pass_FAIL = "USER_FORGOT_pass_FAIL";

export const USER_FORGOT_pass_REQUEST = "USER_FORGOT_pass_REQUEST";
export const USER_FORGOT_pass_SUCCESS = "USER_FORGOT_pass_SUCCESS";
export const USER_FORGOT_pass_FAIL = "USER_UPDATE_pass_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL    = "USER_LOGOUT_FAIL   ";

export const USER_UPDATED_REQUEST = "USER_UPDATED_REQUEST";
export const USER_UPDATED_SUCCESS = "USER_UPDATED_SUCCESS";
export const USER_UPDATED_FAIL    = "USER_UPDATED_FAIL   ";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";
export const USER_RESET_FAIL    = "USER_RESET_FAIL   ";

export const USERS_LIST_ADMIN_REQUEST = "USERS_LIST_ADMIN_REQUEST";
export const USERS_LIST_ADMIN_SUCCESS = "USERS_LIST_ADMIN_SUCCESS";
export const USERS_LIST_ADMIN_FAIL    = "USERS_LIST_ADMIN_FAIL   ";

export const REMOVE_USER_ADMIN_REQUEST = "REMOVE_USER_ADMIN_REQUEST";
export const REMOVE_USER_ADMIN_SUCCESS = "REMOVE_USER_ADMIN_SUCCESS";
export const REMOVE_USER_ADMIN_FAIL    = "REMOVE_USER_ADMIN_FAIL   ";

export const UPDATE_USER_ROLE_ADMIN_REQUEST = "UPDATE_USER_ROLE_ADMIN_REQUEST";
export const UPDATE_USER_ROLE_ADMIN_SUCCESS = "UPDATE_USER_ROLE_ADMIN_SUCCESS";
export const UPDATE_USER_ROLE_ADMIN_FAIL    = "UPDATE_USER_ROLE_ADMIN_FAIL   ";

export const CLEAR_ERROR = "CLEAR_ERROR";
export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST";
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_FAIL =    "PRODUCT_DETAILS_FAIL";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";

export const PRODUCT_REVIEW_REQUEST = "PRODUCT_REVIEW_REQUEST";
export const PRODUCT_REVIEW_FAIL =    "PRODUCT_REVIEW_FAIL";
export const PRODUCT_REVIEW_SUCCESS = "PRODUCT_REVIEW_SUCCESS";

export const PRODUCTS_LIST_ADMIN_REQUEST = "PRODUCTS_LIST_ADMIN_REQUEST";
export const PRODUCTS_LIST_ADMIN_SUCCESS = "PRODUCTS_LIST_ADMIN_SUCCESS";
export const PRODUCTS_LIST_ADMIN_FAIL    = "PRODUCTS_LIST_ADMIN_FAIL   ";

export const REMOVE_PRODUCT_ADMIN_REQUEST = "REMOVE_PRODUCT_ADMIN_REQUEST";
export const REMOVE_PRODUCT_ADMIN_SUCCESS = "REMOVE_PRODUCT_ADMIN_SUCCESS";
export const REMOVE_PRODUCT_ADMIN_FAIL    = "REMOVE_PRODUCT_ADMIN_FAIL   ";

export const PRODUCT_REVIEW_ADMIN_REQUEST = "PRODUCT_REVIEW_ADMIN_REQUEST";
export const PRODUCT_REVIEW_ADMIN_FAIL =    "PRODUCT_REVIEW_ADMIN_FAIL";
export const PRODUCT_REVIEW_ADMIN_SUCCESS = "PRODUCT_REVIEW_ADMIN_SUCCESS";

export const PRODUCT_DELETE_REVIEW_ADMIN_REQUEST = "PRODUCT_DELETE_REVIEW_ADMIN_REQUEST";
export const PRODUCT_DELETE_REVIEW_ADMIN_FAIL =    "PRODUCT_DELETE_REVIEW_ADMIN_FAIL";
export const PRODUCT_DELETE_REVIEW_ADMIN_SUCCESS = "PRODUCT_DELETE_REVIEW_ADMIN_SUCCESS";

export const CREATE_NEW_PRODUCT_ADMIN_REQUEST = "CREATE_NEW_PRODUCT_ADMIN_REQUEST";
export const CREATE_NEW_PRODUCT_ADMIN_FAIL =    "CREATE_NEW_PRODUCT_ADMIN_FAIL";
export const CREATE_NEW_PRODUCT_ADMIN_SUCCESS = "CREATE_NEW_PRODUCT_ADMIN_SUCCESS";

export const UPDATE_PRODUCT_ADMIN_REQUEST = "UPDATE_PRODUCT_ADMIN_REQUEST";
export const UPDATE_PRODUCT_ADMIN_FAIL =    "UPDATE_PRODUCT_ADMIN_FAIL";
export const UPDATE_PRODUCT_ADMIN_SUCCESS = "UPDATE_PRODUCT_ADMIN_SUCCESS";

export const CLEAR_ERROR = "CLEAR_ERROR";